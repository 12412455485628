'use client'

import { FC, useEffect } from 'react'
import { loadIntercom } from '@/lib/intercom'

type Props = {
  userId: string | undefined | null
}

const IntercomLoader: FC<Props> = ({ userId }) => {
  useEffect(() => {
    loadIntercom({
      user_id: userId,
    })
  }, [])
  return null
}

export default IntercomLoader
