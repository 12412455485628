import React, { FC } from 'react'
import LogoWhite from '../../public/assets/images/logo-white.svg'
import Image from 'next/image'

type Props = {
  height?: number
  white?: boolean
}

const LogoImage: FC<Props> = ({ height = 60, white }) => {
  return (
    <Image
      src={LogoWhite}
      height={height}
      alt="logo"
      priority
      className="self-center"
    />
  )
}

export default LogoImage
