import { createNavigation } from 'next-intl/navigation'

import { locales } from './config'
import { defineRouting } from 'next-intl/routing'

export const routing = defineRouting({ locales, defaultLocale: 'en' })

export const {
  Link,
  redirect,
  usePathname,
  useRouter,
  permanentRedirect,
  getPathname,
} = createNavigation({
  locales,
})

export const getApplessRedirectUrl = (path: string) => {
  const [locale = 'en', stationId] = window.location.pathname
    .slice(1)
    .split('/')
  const url = `${window.location.origin}/${locale}/${stationId}${path ?? ''}`
  return url
}
