import { INTERCOM_APP_ID } from './constants'
import { IntercomSettings } from '@intercom/messenger-js-sdk/types'

export const openIntercom = async () => {
  if (typeof window === 'undefined') return

  const Intercom = await import('@intercom/messenger-js-sdk')
  Intercom.show()
}

export const loadIntercom = async (
  settings: Omit<IntercomSettings, 'app_id'>
) => {
  const Intercom = await import('@intercom/messenger-js-sdk')
  Intercom.boot({ app_id: INTERCOM_APP_ID, ...settings })
}
