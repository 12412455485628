'use client'

import React, { FC } from 'react'
import { usePathname } from '@/navigation'
import LogoImage from '@/components/LogoImage'

type Props = {}

const HeaderImage: FC<Props> = () => {
  const pathname = usePathname()

  const hideHeader = pathname.includes('/rent') || pathname.endsWith('/event')

  return hideHeader ? <div /> : <LogoImage height={40} />
}

export default HeaderImage
