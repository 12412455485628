export const locales = [
  'en',
  'cs',
  'de',
  'es',
  'fr',
  'hr',
  'hu',
  'it',
  'lv',
  'mk',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sr',
  'sv',
] as const
export type Locale = (typeof locales)[number]

// wtf
